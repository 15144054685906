// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("./../../../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-404-pt-js": () => import("./../../../src/pages/404.pt.js" /* webpackChunkName: "component---src-pages-404-pt-js" */),
  "component---src-pages-404-ru-js": () => import("./../../../src/pages/404.ru.js" /* webpackChunkName: "component---src-pages-404-ru-js" */),
  "component---src-pages-addrolls-join-en-jsx": () => import("./../../../src/pages/addrolls/join.en.jsx" /* webpackChunkName: "component---src-pages-addrolls-join-en-jsx" */),
  "component---src-pages-addrolls-join-ru-js": () => import("./../../../src/pages/addrolls/join.ru.js" /* webpackChunkName: "component---src-pages-addrolls-join-ru-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

