if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

if ("localStorage" in window) {
  window.localStorage.removeItem("status");
}

const getCookie = (name) => {
  if (typeof document !== "undefined") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
};

const initGa = () => {
  let script = document.createElement("script");

  script.src = `https://www.googletagmanager.com/gtag/js?id=UA-145400661-1%22%3E`;

  document.head.append(script);

  script.onload = () => {
    const user_id = getCookie("duqqy_user_id");

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-145400661-1");
    gtag("config", "AW-929655519");

    if (user_id) {
      gtag("set", { user_id: user_id });
    }
  };

  initLI();
};

const initYM = () => {
  let noscript = document.createElement("noscript");
  noscript.innerHTML =
    '<div><img src="https://mc.yandex.ru/watch/91002433" style="position:absolute; left:-9999px;" alt="" /></div>';

  let script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.innerHTML = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();
    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(91002433, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    });
  `;

  document.head.append(script);
  document.head.append(noscript);

  initVK();
};

const initLI = () => {
  let noscript = document.createElement("noscript");
  noscript.innerHTML =
    '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4836282&fmt=gif" />';

  let script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.innerHTML = `
    _linkedin_partner_id = "4836282"; 
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  `;

  let script2 = document.createElement("script");
  script2.setAttribute("type", "text/javascript");
  script2.innerHTML = `
    (function(l) {
      if (!l){
        window.lintrk = function(a,b) {
          window.lintrk.q.push([a,b])
        }; 
        window.lintrk.q=[]
      } 
      var s = document.getElementsByTagName("script")[0]; 
      var b = document.createElement("script"); 
      b.type = "text/javascript";
      b.async = true; 
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);
  `;

  document.head.append(script);
  document.head.append(script2);
  document.head.append(noscript);
};

const initVK = () => {
  let noscript = document.createElement("noscript");
  noscript.innerHTML =
    '<div><img src="https://top-fwz1.mail.ru/counter?id=3316525;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>';

  let script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.innerHTML = `
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: "3316525", type: "pageView", start: (new Date()).getTime()});
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
      ts.src = "https://top-fwz1.mail.ru/js/code.js";
      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "tmr-code");
  `;

  document.head.append(script);
  document.head.append(noscript);
};

const initMetrics = () => {
  if (
    typeof window !== "undefined" &&
    window.location.host.indexOf(".ru") > -1
  ) {
    initYM();

    return;
  }

  initGa();
};

initMetrics();

exports.shouldUpdateScroll = ({}) => {
  window.scrollTo(0, 0);

  return false;
};
